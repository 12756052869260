<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>

            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='name'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`name.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.name[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='description'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`description.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model='form.description[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>


            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='region_id'
                :name="$t('region')"
              >
                <b-form-group
                  :label="$t('region')"
                >
                  <v-select
                    v-model='form.region_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='regions'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"

                    @input="inputChange('region_id')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
              v-if='cities.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='city_id'
                :name="$t('city')"
              >
                <b-form-group
                  :label="$t('city')"
                >
                  <v-select
                    v-model='form.city_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='cities'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"

                    @input="inputChange('city_id')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='districts.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='district_id'
                :name="$t('district')"
              >

                <b-form-group
                  :label="$t('district')"
                >
                  <v-select
                    v-model='form.district_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='districts'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <!--            <b-col-->
            <!--              md='6'-->
            <!--              cols='12'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('sort')"-->
            <!--                vid='sort'-->
            <!--                :rules='{-->
            <!--                         numeric: true,-->
            <!--                       }'-->
            <!--              >-->
            <!--                <b-form-group>-->
            <!--                  <template #label>-->
            <!--                    <span>{{ $t('sort') }}</span>-->
            <!--                  </template>-->
            <!--                  <b-form-input-->
            <!--                    v-model='form.sort'-->
            <!--                    :placeholder="$t('sort')"-->
            <!--                    autocomplete='off'-->
            <!--                    type='number'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('whatsapp')"
                vid='whatsapp'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('whatsapp') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.whatsapp'
                    :placeholder="'+9XXXXXXXXXXX'"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('x')"
                vid='x'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('x') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.x'
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('snapchat')"
                vid='snapchat'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('snapchat') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.snapchat'
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('instagram')"
                vid='instagram'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('instagram') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.instagram'
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('mobile')"
                vid='mobile'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('mobile') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'

                    v-model='form.mobile'
                    :placeholder="'+9XXXXXXXXXXX'"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='logo'
                :name="$t('labels.logo')"
                rules='required'
              >
                <ImagePreview
                  v-model='form.logo'
                  :label="$t('labels.logo')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12' md='12' class='mt-2'>
              <validation-provider v-slot='{ errors }' name='images' vid='images'>
                <div v-if='!isUploaderLoaded'>
                  <div class='d-flex justify-content-between align-items-center'>
                    <h4>{{ $t('attach_photos') }}</h4>
                    <b-form-file
                      id='images'
                      class='hidden'
                      multiple
                      accept='image/*'
                      @change="uploader($event,'images')"
                    />
                    <label for='images' class='btn btn-primary'>
                      {{ $t('attach') }}
                    </label>
                  </div>

                  <ul v-if='images_arr.length' class='list-uploader uploader-container'>
                    <li v-for='(file, index) in images_arr' :key='index' class='uploader-item'>
                      <div class='image-box'>
                        <div class='img-container'>
                          <img :src='file.url'
                               @error='(event) => handleImageError(event, index)'
                               alt='Image'>
                        </div>
                      </div>
                      <button type='button' class='btn bg-danger uploader-btn'
                              @click="handleDeleteImage(index, 'images_arr')">
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#ffffff'
                             class='bi bi-trash3' viewBox='0 0 16 16'>
                          <path
                            d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z' />
                        </svg>
                      </button>
                      <!--                      <button type='button' class='btn bg-info uploader-btn'-->
                      <!--                              @click.prevent='downloadFile(file, "image")'>-->
                      <!--                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black'-->
                      <!--                             class='bi bi-arrow-bar-down' viewBox='0 0 16 16'>-->
                      <!--                          <path fill-rule='evenodd'-->
                      <!--                                d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5M8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6' />-->
                      <!--                        </svg>-->
                      <!--                      </button>-->
                    </li>
                  </ul>
                  <h5 v-if='!images_arr.length' class='no-files'>
                    {{ $t('No files attached') }}
                  </h5>
                  <small v-if='!images_arr.length' class='text-danger'>
                    {{ errors[0] }}
                  </small>
                </div>

                <div class='text-center text-success my-2' v-else>
                  <span class='mx-2'>{{ $t('file_processing') }}</span>
                  <b-spinner class='align-middle' />
                </div>
              </validation-provider>
            </b-col>


            <!-- checkbox -->
            <!--            <b-col cols='12'>-->
            <!--              <b-card-text class='mb-0'>-->
            <!--                {{ $t('labels.status') }}-->
            <!--              </b-card-text>-->
            <!--              <b-form-checkbox-->
            <!--                v-model='form.status'-->
            <!--                checked='1'-->
            <!--                value='1'-->
            <!--                unchecked-value='0'-->
            <!--                name='check-button'-->
            <!--                switch-->
            <!--              />-->
            <!--            </b-col>-->

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'
import { BFormRadio } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { BFormRadio },
  mixins: [formMixin],
  data() {
    return {
      cities: [],
      regions: [],
      districts: [],
      imagePreviews: [],

      form: {
        sort: null,
        name: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },

        images: [],
        whatsapp: null,

        instagram: null,
        x: null,
        snapchat: null,

        mobile: null,

        logo: null,
        status: 1,
        region_id: null,
        city_id: null,
        district_id: null,
      },

      fields: [],
      isDataLoaded: false,
      isUploaderLoaded: false,
      images_arr: [],
      images_files: [],

    }
  },

  watch: {
    images_arr: {
      handler(val = null) {
        if (val) {
          this.watchFilesChanged('images', val)
        }
      },
    },


  },

  computed: {
    toRedirectResource() {
      return 'real-estate-developer'
    },
  },
  created() {

    (async () => {
      await this.getRegion()
    })()

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    async getRegion() {
      await this.axios.get(`/model-options/region`)
        .then(res => {
          this.regions = res.data.data
        })
    },

    getCities() {
      if (this.form.city_id === null) {
        this.cities = []
      }

      this.axios.get(`/model-options/city?region_id=${this.form.region_id}`)
        .then(res => {
          this.cities = res.data.data
          return 1
        })
    },
    getDistrict() {

      if (this.form.district_id === null) {
        this.districts = []
      }

      this.axios.get(`/districts/list?city_id=${this.form.city_id}`)
        .then(res => {
          this.districts = res.data.data
        })
    },

    inputChange(type) {


      if (type == 'region_id') {
        this.cities = []
        this.form.city_id = null
        this.districts = []
        this.form.district_id = null;

        (async () => {
          await this.getCities()
        })()
      }


      if (type == 'city_id') {
        this.districts = []
        this.form.district_id = null;

        (async () => {
          await this.getDistrict()
        })()
      }
    },


    watchFilesChanged(key, newVal) {
      if (key === 'images') {
        this.form[key] = []
        for (const el of newVal) {
          this.form[key].push(el.name)
        }
        if (this.isEdit && this.images_files.length > 0) {
          this.form[key] = this.images_files
        }
      }
    },

    handleImageError(event, index = 0) {
      if (this.imagePreviews.length > 0) {
        event.target.src = this.imagePreviews[index]
      } else {
        event.target.src = require('@/assets/images/image.svg')
      }
    },

    handleDeleteImage(index, key) {

      this[key].splice(index, 1)

      if (this.isEdit && this.images_files.length > 0 && key === 'images_arr') {
        this.images_files.splice(index, 1)
      }
    },
    uploader(e, key) {
      this.isUploaderLoaded = true

      const { files } = e.target

      if (files.length > 0) {
        const formData = new FormData()
        this.imagePreviews = [] // Reset previews

        for (const file of files) {
          formData.append('files[]', file)

          // Create a preview for each file
          const reader = new FileReader()
          reader.onload = (event) => {
            this.imagePreviews.push(event.target.result)
          }
          reader.readAsDataURL(file)
        }

        formData.append('type', 'array')

        this.axios.post(`/file/uploader/action`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            if (!this.isEdit && key === 'images') {
              for (const el of response.data) {
                this.images_arr.push(el)
              }
            }

            if (this.isEdit && key === 'images') {
              for (const el of response.data) {
                this.images_files.push(el.name)
                this.images_arr.push(el)
              }
            }

            this.isUploaderLoaded = false
          })
          .catch(error => {
            this.isUploaderLoaded = false

            this.$toast({
              component: ToastificationContent,
              position: 'top-start',
              props: {
                title: this.$t('Error'),
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
      }
    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            description: data?.description['ar'] ?? false ? data.description : { en: null, ar: null },

            sort: data.sort,
            whatsapp: data.whatsapp,
            instagram:  data.instagram,
            x:  data.x,
            snapchat:  data.snapchat,

            mobile: data.mobile,

            region_id: data.region_id,
            city_id: data.city_id,
            district_id: data.district_id,

            status: data.status,
            logo: data.logo,

          }

          this.images_arr = data.images_values ? data.images_values : []

          if (this.images_arr.length > 0) {
            this.images_arr.forEach(el => {
              this.images_files.push(el.url)
            })
          }

          delete this.form.images_values

          if (this.form.city_id) {

            (async () => {
              await this.getCities()
            })();

            (async () => {
              await this.getDistrict()
            })()
          }
          this.isDataLoaded = true
        })
    },


  },

}
</script>

<style>
.uploader-container {
  display: flex;
  flex-wrap: wrap;
}

.uploader-item {
  width: 19rem;
  height: 9rem;
  margin: 26px;
}
</style>
